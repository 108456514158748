import Cookies from 'js-cookie';

import { mercuryEnv, uuidGenerator } from './';
const userAttributionIdCookieName = 'user_attribution_id';
const mercuryEnvs = mercuryEnv();
const attributionUuid = () => {
  // determine the environment-specific cookie domains we care about
  let cookieDomains = {
    development: ['localhost'],
    staging: ['wheniwork-staging.com', 'wheniwork.net'],
    acceptance: ['wiw-acceptance.net'],
    production: ['wheniwork.com'],
  };
  
  cookieDomains = cookieDomains[mercuryEnvs];

  let uuid = Cookies.get(userAttributionIdCookieName);

  if (!uuid) {
    uuid = uuidGenerator();
    //track this across all domains
    Object.keys(cookieDomains).map(index => {
      Cookies.set(userAttributionIdCookieName, uuid, {
        expires: new Date('2030-01-01'),
        domain: cookieDomains[index],
      });
    });
  }
  return uuid;
};

export default attributionUuid;
