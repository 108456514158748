import { initialize, LDClient, LDFlagSet, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { LDMultiKindContext } from 'launchdarkly-js-sdk-common';
import { LDSingleKindContext } from 'launchdarkly-react-client-sdk';

import { setLDAnonContext } from 'utils/cookies';
import wiwLDAttributes from 'utils/launch-darkly/wiwLDAttributes';

let ldClient: LDClient;

export const getClient = (): LDClient => {
  if (!ldClient) {
    const anonContext: LDSingleKindContext = { anonymous: true, kind: 'anonymous-user' };
    const userContextAttributes = wiwLDAttributes.getAttributes();
    const multiContext: LDMultiKindContext = {
      kind: 'multi',
    };

    multiContext.user = {
      kind: 'user',
      key: `user:${userContextAttributes?.userId}`,
      ...userContextAttributes,
    };

    multiContext['anonymous-user'] = anonContext;
    const context = userContextAttributes.userId != '' ? multiContext : anonContext;

    ldClient = initialize(
      `${process.env.GATSBY_LAUNCHDARKLY_CLIENTID}`,
      context,
      {
        application: { id: 'marketing' },
        sendEventsOnlyForVariation: true,
      }
    );
  }
  return ldClient;
};

export function ldTrack(key: string, data?: any, metricValue?: number) {
  return getClient().track(key, data, metricValue);
}

export const getLDFlags = (): Promise<LDFlagSet> => {
  return new Promise((resolve, reject) => {
    getClient().waitForInitialization().then(() => {
      resolve(ldClient.allFlags());
    }).catch(err => {
      reject(new Error('Failed to retrieve LD flags'));
    });
  });
};

export const getLDFlagVariation = (flag: string): Promise<LDFlagValue> => {
  return new Promise((resolve, reject) => {
    getClient().waitForInitialization().then(() => {
      resolve(ldClient.variation(flag));
    }).catch(err => {
      reject(new Error('Failed to retrieve LD flag variation'));
    });
  });
};

export function ldSetAnonCookie() {
  getClient().waitForInitialization().then(() => {
    const ldContext = ldClient.getContext();

    if (ldContext.anonymous) {
      setLDAnonContext(ldContext.key);
    }
  });
}

export default function useLD() {
  return {
    track: ldTrack,
  };
}
