export default function getBoolean(value: boolean | string | undefined) {
  switch (value) {
    case true:
    case 'true':
      return true;
    case false:
    case 'false':
    case undefined:
      return false;
  }
}