import axios from 'axios';
import Cookies from 'js-cookie';

import { windowCheck } from 'utils/helpers';

import { PLATFORM_API_URL } from 'components/forms/constants';

type WIWLDAttributes = {
  accountBillingType: number
  accountConvertedAt: number
  accountCreatedAt: number
  accountHasMaster: boolean
  accountId: string
  accountIsActive: boolean
  accountIsMaster: boolean
  accountName: string
  accountPlanExpires: number
  accountRefEmployees: string
  accountTrialCreatedAt: number
  email: string,
  firstName: string
  lastName: string
  masterAccountId: string
  masterPlanId: string
  planId: string
  planIsFreemium: string
  planIsPaid: string
  planName: string
  planType: number
  userId: string
  userIsActivated: boolean
  userIsDeleted: boolean
  userIsHidden: boolean
  userRole: string
}

const emptyWIWLDAttributes = {
  accountBillingType: 0,
  accountConvertedAt: 0,
  accountCreatedAt: 0,
  accountHasMaster: false,
  accountId: '',
  accountIsActive: false,
  accountIsMaster: false,
  accountName: '',
  accountPlanExpires: 0,
  accountRefEmployees: '',
  accountTrialCreatedAt: 0,
  email: '',
  firstName: '',
  lastName: '',
  masterAccountId: '',
  masterPlanId: '',
  planId: '',
  planIsFreemium: '',
  planIsPaid: '',
  planName: '',
  planType: 0,
  userId: '',
  userIsActivated: false,
  userIsDeleted: false,
  userIsHidden: false,
  userRole: '',
};

class wiwLDAttributes {
  public accountBillingType: number = 0;
  public accountConvertedAt: number = 0;
  public accountCreatedAt: number = 0;
  public accountHasMaster: boolean = false;
  public accountId: string = '';
  public accountIsActive: boolean = false;
  public accountIsMaster: boolean = false;
  public accountName: string = '';
  public accountPlanExpires: number = 0;
  public accountRefEmployees: string = '';
  public accountTrialCreatedAt: number = 0;
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public masterAccountId: string = '';
  public masterPlanId: string = '';
  public planId: string = '';
  public planIsFreemium: string = '';
  public planIsPaid: string = '';
  public planName: string = '';
  public planType: number = 0;
  public userId: string = '';
  public userIsActivated: boolean = false;
  public userIsDeleted: boolean = false;
  public userIsHidden: boolean = false;
  public userRole: string = '';

  public hasFetched = false;

  constructor() {
    this.getData();
  }

  private makeWIWLDAttributes(): WIWLDAttributes {
    return {
      accountBillingType: this.accountBillingType,
      accountConvertedAt: this.accountConvertedAt,
      accountCreatedAt: this.accountCreatedAt,
      accountHasMaster: this.accountHasMaster,
      accountId: this.accountId,
      accountIsActive: this.accountIsActive,
      accountIsMaster: this.accountIsMaster,
      accountName: this.accountName,
      accountPlanExpires: this.accountPlanExpires,
      accountRefEmployees: this.accountRefEmployees,
      accountTrialCreatedAt: this.accountTrialCreatedAt,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      masterAccountId: this.masterAccountId,
      masterPlanId: this.masterPlanId,
      planId: this.planId,
      planIsFreemium: this.planIsFreemium,
      planIsPaid: this.planIsPaid,
      planName: this.planName,
      planType: this.planType,
      userId: this.userId,
      userIsActivated: this.userIsActivated,
      userIsDeleted: this.userIsDeleted,
      userIsHidden: this.userIsHidden,
      userRole: this.userRole,
    };
  }

  private setWIWLDAttributes(data: WIWLDAttributes) {
    this.accountBillingType = data.accountBillingType;
    this.accountConvertedAt = data.accountConvertedAt;
    this.accountCreatedAt = data.accountCreatedAt;
    this.accountHasMaster = data.accountHasMaster;
    this.accountId = data.accountId;
    this.accountIsActive = data.accountIsActive;
    this.accountIsMaster = data.accountIsMaster;
    this.accountName = data.accountName;
    this.accountPlanExpires = data.accountPlanExpires;
    this.accountRefEmployees = data.accountRefEmployees;
    this.accountTrialCreatedAt = data.accountTrialCreatedAt;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.masterAccountId = data.masterAccountId;
    this.masterPlanId = data.masterPlanId;
    this.planId = data.planId;
    this.planIsFreemium = data.planIsFreemium;
    this.planIsPaid = data.planIsPaid;
    this.planName = data.planName;
    this.planType = data.planType;
    this.userId = data.userId;
    this.userIsActivated = data.userIsActivated;
    this.userIsDeleted = data.userIsDeleted;
    this.userIsHidden = data.userIsHidden;
    this.userRole = data.userRole;
  }

  public getAttributes(): WIWLDAttributes {
    return this.makeWIWLDAttributes();
  }
  
  public async getData(): Promise<WIWLDAttributes> {
    if (!windowCheck) {
      return emptyWIWLDAttributes;
    }

    if (this.hasFetched) {
      return this.makeWIWLDAttributes();
    }

    try {
      const userIdCookie = Cookies.get('W-UserId');
      const tokenCookie = Cookies.get('W-Token');
      if (!userIdCookie || !tokenCookie) {
        return emptyWIWLDAttributes;
      }

      const response = await axios.get(`${PLATFORM_API_URL}/identify`, {
        headers: {
          'W-UserId': userIdCookie,
          'W-Token': tokenCookie,
        },
      });

      if (!response.data) {
        return emptyWIWLDAttributes;
      }

      const ldAttributes = response.data.data && response.data.data.launchdarkly ? response.data.data.launchdarkly : null;

      if (!ldAttributes) {
        return this.makeWIWLDAttributes();
      }

      this.setWIWLDAttributes({ ...ldAttributes });
      this.hasFetched = true;
      return this.makeWIWLDAttributes();

    } catch (error: any) {
      this.hasFetched = true;
      if (error.response) {
        console.error('response data: ', error.response.data);
        console.error('response status: ', error.response.status);
      }
      return emptyWIWLDAttributes;
    }
    
  }
}

export default new wiwLDAttributes();