// Signup messages
export const RESUBMIT_WITHOUT_PLACE_DATA = 'resubmit_without_place_data';
export const CANCEL_SIGNUP = 'cancel_signup';
export const DUPLICATE_EMAIL_ERROR = 'duplicate_email';
export const DUPLICATE_PLACE_DATA_ERROR = 'duplicate_place';
export const UNKNOWN_SIGNUP_ERROR = 'unexpected_signup_error';
export const INVALID_PROMO_CODE = 5010;
export const MISSING_TIMEZONE = 5030;
export const EMAIL_ALREADY_EXISTS = 5140;
export const PLACE_ALREADY_CLAIMED = 2721;
//Mercury Event Identifiers
export const MARKETING_SITE_EVENT_IDENTIFIER = 'marketing::marketingSiteEvent';
export const MARKETING_ON_INTERACTION = 'marketing::onInteraction'; // track clicks: setup data attributes for buttons
export const MARKETING_PAGE_VIEW = 'marketing::pageView'; // Fires when page route changes to send current page url: previous aka referur and path with is current page
export const MARKET_SITE_USER_ACQUISITION_EVENT_IDENTIFIER = 'marketingAcquisition::newSession';
export const MARKETING_SITE_EXPERIMENT_EVENT_IDENTIFIER = 'marketingAcquisition::experiment';
export const MARKETING_HOMEPAGE_EXPERIMENT_EVENT_IDENTIFIER = 'experiment::mrkt751HomepageRedesign';
export const EXPERIMENT_ASSIGNMENTS_IDENTIFIER = 'experiment::assignments';