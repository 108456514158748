import windowCheck from '../../helpers/window-check';

const getMercuryOrigin = (test, key) => {
  windowCheck ? (test = window.location.hostname) : '';

  const marketing = 'marketing';
  const unknown = 'marketing';
  const fallback = unknown;
  const map = {
    localhost: marketing,
    'marketing.wheniwork-staging': marketing,
    'wiw-acceptance': marketing,
    'wheniwork.com': marketing,
  };
  key = Object.keys(map).find(element => {
    return element === test;
  });

  if (key !== undefined) {
    return map[key];
  }

  return fallback;
};

export default getMercuryOrigin;
