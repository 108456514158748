//import trackEvent from '../mercury/trackOnInteraction';
import { camelCase } from 'lodash';

import windowCheck from 'utils/helpers/window-check';

import stripHtmlComments from '../../helpers/stripHtmlComments';
import trackEvent from '../trackOnInteraction';

function getModuleElement(eventTarget) {
  return !!eventTarget && typeof eventTarget.closest === 'function' && eventTarget.closest('[data-section]') && eventTarget.closest('[data-section]').dataset.section
    ? eventTarget.closest('[data-section]').dataset.section
    : 'could not find Module Name';
}

const trackingWindowEvents = () => {
  if (windowCheck)
    return document.body.addEventListener('click', event => {
      const element = event.target;
      const clickedElement = element.tagName.toLowerCase();
      let destinationElement, targetElement, moduleElement;

      const trackLink = event => {
        const action = 'click';
        const module = moduleElement(event);
        const destination = destinationElement(event);
        const target = targetElement(event);
        trackEvent(action, module, target, destination);
      };

      switch (true) {
        case clickedElement === 'a':
          moduleElement = () => getModuleElement(element);
          destinationElement = () => element.href;
          targetElement = () => {
            stripHtmlComments(element.innerHTML);
            return camelCase(element.innerText);
          };
          trackLink(event);
          break;
        case clickedElement === 'button':   
          moduleElement = () => getModuleElement(element);         
          destinationElement = () =>
            windowCheck ? window.location.pathname : 'no path specified';
          targetElement = () => {
            stripHtmlComments(element.innerHTML);
            return camelCase(element.innerText);
          };
          trackLink(event);
          break;
        case clickedElement === 'img' &&
          element.parentNode.tagName.toLowerCase() === 'a':
          moduleElement = () => getModuleElement(element);
          destinationElement = () => element.parentElement.href;
          targetElement = () => camelCase(element.alt);
          trackLink(event);
          break;
        case clickedElement === 'svg' &&
          element.parentNode.tagName.toLowerCase() === 'a':
          moduleElement = () => getModuleElement(element);
          destinationElement = () => element.parentElement.href;
          targetElement = () => camelCase(element.parentElement.innerText);
          trackLink(event);
          break;
      }
    });
};
export default trackingWindowEvents;
