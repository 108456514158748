import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

const USER_ATTRIBUTION_ID = 'user_attribution_id';
const USER_FIRST_SEEN = 'user_first_seen';
const USER_SESSIONS = 'user_sessions';
const LD_USER_GROUP = 'ld_user_group';
const FIVE_YEARS = 1825;
const LD_ANON_CONTEXT = 'ld_anon_context';

function createGetOrSet(key, generator, updater) {
  const getOrSet = () => {
    let value = Cookies.get(key);

    if (!value) {
      // If the cookie isn't set, run the generator
      value = generator();
      Cookies.set(key, value, { expires: FIVE_YEARS });
    } else if (updater) {
      // otherwise if there's an updater function and an existing cookie, call the updater
      value = updater(value);
      Cookies.set(key, value, { expires: FIVE_YEARS });
    }

    return value;
  };

  return getOrSet;
}

function generateRandomNumber() {
  return Math.round(Math.random() * 1000);
}

export const getOrSetUserFirstSeen = () => {
  const cookieValue = Cookies.get(USER_FIRST_SEEN);
  const parsedValue = Number(cookieValue);

  let firstSeenValue;

  if (!cookieValue || isNaN(parsedValue)) {
    // if not set, or value isn't a number
    firstSeenValue = Date.now();
    Cookies.set(USER_FIRST_SEEN, firstSeenValue, { expires: FIVE_YEARS });
    return firstSeenValue;
  } else {
    return parsedValue;
  }
};

export const setLDAnonContext = LDKey => {
  const domain = `${process.env.GATSBY_SITE_URL}`;
  Cookies.set(LD_ANON_CONTEXT, LDKey, { domain: domain });
};

export const getOrSetUserAttributionId = createGetOrSet(
  USER_ATTRIBUTION_ID,
  uuid()
);

export const getOrSetLDRandomGroup = createGetOrSet(
  LD_USER_GROUP,
  generateRandomNumber
);

export const getOrSetSessionCount = createGetOrSet(
  USER_SESSIONS,
  () => 1,
  val => parseInt(val, 10) + 1
);

export const W_USER_ID = value => Cookies.set('W-UserId', value);
