import queryString from 'query-string';

import windowCheck from 'utils/helpers/window-check';

// method to parse query string, will store as json in mercury (but passed as a json string over http)
export const params = (stringify, search) => {
  stringify = true;
  if (windowCheck) {
    search = window.location.search
      .substring(1)
      .replace(/^(&)/, '')
      .replace(/(&)$/, '');
  }

  if (stringify) {
    return !search ? null : JSON.stringify(queryString.parse(search));
  } else {
    return !search ? {} : queryString.parse(search);
  }
};
