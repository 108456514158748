import Cookies from 'js-cookie';
import mixpanel from 'mixpanel-browser';

import './src/styles/config.scss';
import {
  getOrSetSessionCount,
  getOrSetUserAttributionId,
  getOrSetUserFirstSeen,
} from './src/utils/cookies';
import rootWrappers from './src/utils/gatsby/shared/root-wrappers';
import createBoolean from './src/utils/helpers/createBoolean';
import { getClient, getLDFlags, ldSetAnonCookie } from './src/utils/launch-darkly/useLD';
import trackingWindowEvents from './src/utils/mercury/partials/window-events';
import trackPageView from './src/utils/mercury/trackPageView';
import trackUserAcquisition from './src/utils/mercury/trackUserAcquisition';
import { reportLDFlags } from './src/utils/mixpanel/reportLDFlags';
import wiwUser from './src/utils/wiw-user';


// The following code adds spotty support for jumplinks in gatsby.
// todo: rework these two funcitons
const scrollToHash = () => {
  // Check for location so build does not fail
  if (location && location.hash) {
    try {
      let item = window.scrollY + document.querySelector(`${location.hash}`).getBoundingClientRect().top;
      // 100 = sticky main nav height
      item = item - 100;
      window.scrollTo({
        top: item,
        behavior: 'smooth',
      });
    } catch (err) {
      console.log(
        'There is a hash in your url but there is no id that matches inside your markup!',
        err
      );
    }
  }

  return true;
};

const scrollToAnchor = () => {
  try {
    [...document.querySelectorAll('a[href^="#"]')].map(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        let item =
          window.pageYOffset +
          document
            .querySelector(this.getAttribute('href'))
            .getBoundingClientRect().top;
        // 100 = sticky main nav height
        item = item - 100;
        window.scrollTo({
          top: item,
          behavior: 'smooth',
        });
      });
    });
  } catch (err) {
    console.log('scrollToAnchor function missed', err);
  }

  return true;
};

const allowPositionStickyToWork = () => {
  if (
    window.location.pathname === '/integrations' ||
    window.location.pathname === '/pricing'
  ) {
    const element = document.querySelector('[role="main"]');
    return element?.classList.add('remove-overflow');
  }
};

// Gatsby wrappers
export const wrapRootElement = rootWrappers;

// Gatsby on client entry function: Fires first before all other gatsby-browser API's
export function onClientEntry() {
  if (typeof window.wiw !== 'object') {
    window.wiw = {
      app_url: process.env.GATSBY_WIW_APP_URL,
      login_url: process.env.GATSBY_WIW_LOGIN_URL,
      api_endpoint: process.env.GATSBY_WIW_API_ENDPOINT,
      ld_id: process.env.GATSBY_LAUNCHDARKLY_CLIENTID,
      login_status: createBoolean(Cookies.get('W-Login')),
    };
  }

  allowPositionStickyToWork();
}

let nextRoute = '';

export function onPreRouteUpdate({ location }) {
  nextRoute = location.pathname;
}

window.addEventListener('unhandledrejection', event => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});

// Gatsby on route update function
export function onRouteUpdate({ location, prevLocation }) {
  wiwUser.getData().then(() => {
    trackPageView();
    mixpanel.track_pageview(location.pathname);
  });

  scrollToHash();
  scrollToAnchor();
  allowPositionStickyToWork();

  window.currentLocation = location.pathname;
  window.previousLocation = prevLocation ? prevLocation.pathname : null;

  if (window.currentLocation === '/blog/inspiring-small-business-stories-50-states' && document.getElementById('select-state')) {
    const dropdown:HTMLSelectElement = document.getElementById('select-state');
    const usaMap = document.querySelector('.us-state-map');

    dropdown?.addEventListener('change', () => {
      document?.querySelector(`#${dropdown.value}`).scrollIntoView({ 
        behavior: 'smooth', 
      });
    });

    usaMap?.addEventListener('click', event => {
      const state = event.target.getAttribute('data-name');
      document?.querySelector(`#${state}`).scrollIntoView({ 
        behavior: 'smooth', 
      });
    });
  }

  return;
}

// Gatsby on initial client render function
export function onInitialClientRender() {

  getOrSetUserFirstSeen();
  getOrSetUserAttributionId();
  getOrSetSessionCount();

  wiwUser.getData().then(user => {
    trackUserAcquisition();
  });
  //Initialize LD Client
  getClient();
  ldSetAnonCookie();
  setMixpanelSuperProperties();
  trackingWindowEvents();
}

const setMixpanelSuperProperties = () => {
  getLDFlags().then(flags => {
    mixpanel.register({ 'CAT LD Flags': reportLDFlags(flags, 'cat-'), 'FDT LD Flags': reportLDFlags(flags, 'fdt-') });
  }).catch(err => console.log(err));
};

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  if (location.hash) {
    return false && scrollToAnchor();
  }

  return true;
}
