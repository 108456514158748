/*eslint no-console: 0*/
const logger = class {
  log(...args) {
    if (this.enabled) {
      console.log(...args);
    }
  }

  info(...args) {
    if (this.enabled) {
      console.info(...args);
    }
  }

  error(...args) {
    if (this.enabled) {
      console.error(...args);
    }
  }

  warn(...args) {
    if (this.enabled) {
      console.warn(...args);
    }
  }

  get enabled() {
    if (window.wiw) {
      if (
        window.wiw.debug === '1' ||
        window.wiw.debug === true ||
        window.wiw.debug === 1
      ) {
        return true;
      }
    }
    return false;
  }
};

export default new logger();
