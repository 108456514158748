import { createContext } from 'react';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

export const MixpanelContext = createContext<OverridedMixpanel>({} as OverridedMixpanel);

const MixpanelProvider = ({ children }) => {
  mixpanel.init(`${process.env.GATSBY_MIXPANEL_TOKEN}`);
  
  return (
    <MixpanelContext.Provider value={ mixpanel }>
      {children}
    </MixpanelContext.Provider>
  );
};

export default MixpanelContext;

export { MixpanelProvider };