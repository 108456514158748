import { withLDProvider } from 'launchdarkly-react-client-sdk';

// This provider wraps the entire Gatsby site and allows the use
// of the LaunchDarkly hooks to get flags or track users
export default () =>
  withLDProvider({
    clientSideID: `${process.env.GATSBY_LAUNCHDARKLY_CLIENTID}`,
    reactOptions: { useCamelCaseFlagKeys: false },
    options: {
      bootstrap: 'localStorage',
      streaming: false,
      application: { id: 'marketing' },
      sendEventsOnlyForVariation: true,
    },
    context: { anonymous: true, kind: 'anonymous-user' },
  });
