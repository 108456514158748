import Cookies from 'js-cookie';

import windowCheck from 'utils/helpers/window-check';

import { MARKET_SITE_USER_ACQUISITION_EVENT_IDENTIFIER } from '../constants';
import { params as stringifyParams } from './partials/params';
import referralId from './partials/referralId';
import trackMercuryEvent from './trackMercuryEvent';

const params = () => stringifyParams();
const parsedParams = JSON.parse(params(false));

// The trigger configured in GTM is that referrer does not contain "wheniwork."
export default function () {
  // attributes in data catalog for this events
  const fields = {
    gaClientId: Cookies.get('_ga') ? Cookies.get('_ga') : null,
    marketoCookie: null,
    kenshooCookieId: null,
    query: windowCheck ? window.location.search.substring(1) : null,
    params: params(),
    utmCampaign:
      parsedParams === null
        ? null
        : typeof parsedParams['utm_campaign'] == 'undefined'
          ? null
          : parsedParams['utm_campaign'],
    utmContent:
      parsedParams === null
        ? null
        : typeof parsedParams['utm_content'] == 'undefined'
          ? null
          : parsedParams['utm_content'],
    utmMedium:
      parsedParams === null
        ? null
        : typeof parsedParams['utm_medium'] == 'undefined'
          ? null
          : parsedParams['utm_medium'],
    utmSource:
      parsedParams === null
        ? null
        : typeof parsedParams['utm_source'] == 'undefined'
          ? null
          : parsedParams['utm_source'],
    utmTerm:
      parsedParams === null
        ? null
        : typeof parsedParams['utm_term'] == 'undefined'
          ? null
          : parsedParams['utm_term'],
    channel:
      parsedParams === null
        ? null
        : typeof parsedParams['channel'] == 'undefined'
          ? null
          : parsedParams['channel'],
    referralId: referralId(),
  };

  //Fire on any page when a referrer is not a when i work domanin.
  // Also fire on all landing pages since these may be sent by redirect.
  if (windowCheck) {
    if (
      document.referrer.indexOf('wheniwork.') === -1 &&
      document.referrer.indexOf('whenidev.') === -1
    ) {
      return trackMercuryEvent(
        MARKET_SITE_USER_ACQUISITION_EVENT_IDENTIFIER,
        fields
      );
    } else if (window.location.pathname.search(/\/l\//) !== -1) {
      return trackMercuryEvent(
        MARKET_SITE_USER_ACQUISITION_EVENT_IDENTIFIER,
        fields
      );
    }
  }
}
