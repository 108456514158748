import axios from 'axios';
import Cookies from 'js-cookie';

import { API_ENDPOINT } from 'components/forms/constants';

import { windowCheck } from '../helpers';

type WIWUser = {
  accountId: string | null
  loggedIn: boolean
  personId: string | null
  userId: string | null
}

const emptyWIWUser: WIWUser = {
  accountId: null,
  loggedIn: false,
  personId: null,
  userId: null,
};

export default class wiwUser {

  public accountId: string | null = null;
  public loggedIn = false;
  public personId: string | null = null;
  public userId: string | null = null;

  private hasFetched = false;

  constructor() {
    this.getData();
  }

  private makeWIWUser(): WIWUser {
    return {
      accountId: this.accountId,
      loggedIn: this.loggedIn,
      personId: this.personId,
      userId: this.userId,
    };
  }

  private removeCookies() {
    Cookies.remove('W-AccountId');
    Cookies.remove('W-PersonId');
    Cookies.set('W-Login', 'false');
  }

  private setCookies() {
    Cookies.set('W-Login', `${this.loggedIn}`);

    if (this.accountId)
      Cookies.set('W-AccountId', this.accountId);
    
    if (this.personId)
      Cookies.set('W-PersonId', this.personId);
  }

  private setWIWUser(data: WIWUser) {
    this.accountId = data.accountId;
    this.loggedIn = data.loggedIn;
    this.personId = data.personId;
    this.userId = data.userId;
  }

  public async getData(): Promise<WIWUser> {

    if (!windowCheck) {
      return emptyWIWUser;
    }

    if (this.hasFetched) {
      return this.makeWIWUser();
    }

    try {
      const userIdCookie = Cookies.get('W-UserId');
      const tokenCookie = Cookies.get('W-Token');
      if (!userIdCookie || !tokenCookie) {
        this.removeCookies();
        return emptyWIWUser;
      }
      
      const response = await axios.get(`${API_ENDPOINT}/users/${userIdCookie}`, {
        headers: {
          'W-UserId': userIdCookie,
          'W-Token': tokenCookie,
        },
      });

      if (!response.data) {
        this.removeCookies();
        return emptyWIWUser;
      }

      if (response.data && response.data.error) {
        this.removeCookies();
        return this.makeWIWUser();
      }

      const user = response.data && response.data.user
        ? response.data.user
        : null;

      if (!user) {
        this.removeCookies();
        return this.makeWIWUser();
      }

      this.setWIWUser({
        accountId: user.account_id,
        personId: user.login_id,
        userId: user.id,
        loggedIn: !!user.id && !!user.account_id,
      });

      if (this.loggedIn) {
        this.setCookies();
      } else {
        this.removeCookies();
      }

      this.hasFetched = true;

      return this.makeWIWUser();

    } catch (error: any) {
      this.hasFetched = true;

      if (error.response) {
        if (error.response.status === 401) {
          // swallow error and remove cookies
          this.removeCookies();
        } else {
          // Request made and server responded
          console.error('response data: ', error.response.data);
          console.error('response status: ', error.response.status);
          console.error('response headers: ', error.response.headers);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
      }

      return emptyWIWUser;
    }

  }
}
