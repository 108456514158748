import Cookies from 'js-cookie';
import Mercury from 'mercury-js';

import { EXPERIMENT_ASSIGNMENTS_IDENTIFIER } from 'utils/constants';

import { getOrSetUserAttributionId } from '../cookies';
import { logger } from '../helpers';
import wiwUser from '../wiw-user';
import { attributionUuid, mercuryEnv, origin } from './partials';

const mercury = new Mercury();
// Hiding window and document object from gatsby build
const windowHostName =
  typeof window !== 'undefined' && window.location.hostname;
const windowPath = typeof window !== 'undefined' && window.location.pathname;
const documentReferrer = typeof document !== 'undefined' && document.referrer;
// Setting up global data
const hostName = windowHostName === '' ? null : windowHostName;
const path = windowPath;
const referrer = documentReferrer === '' ? null : documentReferrer;
const cookieId = attributionUuid();
const origins = origin();
const mercuryEnvs = mercuryEnv();
// The trigger configured in GTM is that referrer does not contain "wheniwork."
export default function trackMercuryEvent(identifier, attributes = {}) {
  if (identifier === null || typeof attributes !== 'object') {
    logger.error(
      'You must provide an identifier attributes must be passed as an object'
    );
    return;
  }

  // attributes in data catalog for this event
  // fixing missing values later. Shouldn't be passing null
  let fields = {};
  // if identifier is equal to marketing::formSubmissions then no additional fields will be added
  // marketing::formSubmissions is vero forms specifically
  if (identifier === 'marketing::formSubmissions' || identifier === EXPERIMENT_ASSIGNMENTS_IDENTIFIER) {
    fields = Object.assign({}, formatAttributes(attributes));
  } else {
    fields = Object.assign(
      {},
      {
        cookieId,
        hash: null,
        hostName,
        path,
        referrer,
      },
      formatAttributes(attributes)
    );
  }

  let accountId =
    typeof Cookies.get('W-AccountId') !== 'undefined'
      ? Cookies.get('W-AccountId')
      : null;

  //password screen sets W-Token and W-UserId cookies so it doesn't need to exist after we gleen the data we want
  let userId =
    typeof Cookies.get('W-UserId') !== 'undefined'
      ? Cookies.get('W-UserId')
      : null;

  // personId is supposed to be the login service id, fall back to our uuid if we dont' have one
  let personId =
    typeof Cookies.get('W-PersonId') !== 'undefined'
      ? Cookies.get('W-PersonId')
      : null;

  if (accountId === null) {
    accountId =
      wiwUser.accountId !== null ? JSON.stringify(wiwUser.accountId) : '';
  }
  if (userId === null) {
    userId = wiwUser.userId !== null ? JSON.stringify(wiwUser.userId) : '';
  }
  if (personId === null) {
    personId =
      wiwUser.personId !== null ? JSON.stringify(wiwUser.personId) : '';
  }

  const data = {
    meta: {
      accountId,
      userId,
      personId,
    },
  };

  if (!accountId && !userId && !personId) {
    // we need to provide something for the partition key, usually defaults to accountId, userId, or personId when provided.
    data.key = fields.cookieId;
  }

  mercury.identify(accountId, userId, personId, origins, mercuryEnvs);
  return mercury.track(identifier, fields, data);
}

export function trackMercuryEventWithFields(name, inputFields) {
  if (!name || !inputFields) {
    return;
  }

  const accountId =
    wiwUser.accountId !== null ? JSON.stringify(wiwUser.accountId) : '';

  const userId = wiwUser.userId !== null ? JSON.stringify(wiwUser.userId) : '';

  // personId is supposed to be the login service id, fall back to our uuid if we dont' have one
  const personId =
    wiwUser.personId !== null ? JSON.stringify(wiwUser.loginId) : '';

  const data = {
    meta: {
      accountId,
      userId,
      personId,
    },
  };

  if (!accountId && !userId && !personId) {
    // we need to provide something for the partition key, usually defaults to accountId, userId, or personId when provided.
    data.key = getOrSetUserAttributionId();
  }

  mercury.identify(accountId, userId, personId, origins, mercuryEnvs);
  mercury.track(name, inputFields, data);
}

const formatAttributes = attributes => {
  for (const [key, value] of Object.entries(attributes)) {
    if (typeof value === 'object' && value !== null) {
      attributes[key] = JSON.stringify(value);
    }
  }

  return attributes;
};
