import { createContext, useEffect, useState } from 'react';

import wiwLDAttributes from 'utils/launch-darkly/wiwLDAttributes';

import wiwUser from '../../wiw-user';

const LoginContext = createContext({
  loggedIn: false,
  ldIdentified: false,
});

const LoginProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [ldIdentified, setLdIdentified] = useState(false);
  const value = {
    loggedIn,
    setLoggedIn,
    ldIdentified,
    setLdIdentified,
  };

  const determineLoggedInStatus = async () => {
    const data = await wiwUser.getData();
    setLoggedIn(data.loggedIn);
    const ldData = await wiwLDAttributes.getData();
    setLdIdentified(!!ldData.userId);
  };

  useEffect(() => {
    determineLoggedInStatus();
  }, []);

  return (
    <LoginContext.Provider value={ value }>{children}</LoginContext.Provider>
  );
};

export default LoginContext;

export { LoginProvider };
