import windowCheck from 'utils/helpers/window-check';

const getEnvironmentId = (test, key) => {
  const dev = 'development';
  const staging = 'staging';
  const acceptance = 'acceptance';
  const prod = 'production';
  const fallback = dev;
  const map = {
    localhost: dev,
    'wheniwork-staging.com': staging,
    'wheniwork.net': staging,
    'wiw-acceptance.net': acceptance,
    'wheniwork.com': prod,
  };

  if (windowCheck) {
    test = window.location.hostname;

    key = Object.keys(map).find(element => test.indexOf(element) !== -1);
  }
  if (key !== undefined) {
    return map[key];
  }
  return fallback;
};

export default getEnvironmentId;
