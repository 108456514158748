/* eslint no-console: "off" */
import { MARKETING_ON_INTERACTION } from '../constants';
import { params } from './partials/params';
import trackMercuryEvent from './trackMercuryEvent';

// The trigger configured in GTM is that referrer does not contain "wheniwork."

export default function trackEvent(
  action,
  module,
  target,
  destination,
  annotations = null,
  referrer = null,
  queryParams = params()
) {
  // attributes in data catalog for this event
  const fields = {
    action,
    module,
    target,
    destination,
    annotations,
    referrer,
    queryParams,
  };

  if (action === null || module === null || target === null) {
    console.error(
      'You must provide an module/target and action to track an website event'
    );
    return;
  }
  // // window.mercury object has public methods for track, page, and identify
  return trackMercuryEvent(MARKETING_ON_INTERACTION, fields);
}
