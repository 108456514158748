/* eslint no-console: "off" */
import { MARKETING_PAGE_VIEW } from '../constants';
import { params } from './partials/params';
import trackMercuryEvent from './trackMercuryEvent';
// The trigger configured in GTM is that referrer does not contain "wheniwork."

export default function trackPageView(
  hostName = window.location.hostname,
  hash = null,
  annotations = null,
  queryParams = params()
) {
  // attributes in data catalog for this event
  const fields = {
    annotations,
    hostName,
    hash,
    queryParams,
  };

  // // window.mercury object has public methods for track, page, and identify
  return trackMercuryEvent(MARKETING_PAGE_VIEW, fields);
}
