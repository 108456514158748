import { params as parseParams } from './params';


export default function getReferralId() {

  const params = JSON.parse(parseParams());

  if (params === null) {
    return null;
  } else {
    let id = typeof params['referralId'] == 'undefined' ? null : params;
    if (id === null && typeof params['ref'] === 'string') {
      if (params['ref'].trim().indexOf('wiw.') === 0) {
        id = params['ref'];
      }
    }
    return id;
  }

}