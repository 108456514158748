import { LDFlagSet } from 'launchdarkly-js-client-sdk';

export function reportLDFlags(ldFlags: LDFlagSet, substring: string): string[] {
  const LDFlags: string[] = [];

  for (const [key, value] of Object.entries(ldFlags)) {
    if (key.includes(substring)) {
      LDFlags.push(`${ key }: ${ typeof value === 'object' ? value?.current : value }`);
    }
  }

  return LDFlags;
}